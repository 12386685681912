<section class="wow fadeInUp" data-wow-delay="0.2s" id="footer">
  <div class="container-fluid base-color text-white">
    <div class="container">
      <div class="row">
        <div class="col-sm-6 col-md-3 col-lg-2">
          <div class="media-wrap">
            <div class="m-4 logo">
              <img class="img-fluid" alt="Phoenix Blinds Logo" src="../../../assets/images/phoenix-logo-wht-lg.png">
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-md-3 col-lg-2">
          <h4 class="pt-2">
            Address
          </h4>
          <p class="mbr-text">
            Phoenix Binds<br />199 Milton Road<br>Southsea<br>Portsmouth<br>Hampshire<br>PO4 8PH
          </p>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-4">
          <h4 class="pt-2">
            Contacts
          </h4>
          <p class="mbr-text">
            <i class="fa-solid fa-envelope"></i>&nbsp;&nbsp;<a
              href="mailto:info@phoenixblindsandshutters.co.uk">info&#64;phoenixblindsandshutters.co.uk</a><br />
          </p>
          <p class="mbr-text">
            <i class="fa-solid fa-envelope"></i>&nbsp;&nbsp;
            <a href="mailto:sales@phoenixblindsandshutters.co.uk">sales&#64;phoenixblindsandshutters.co.uk</a><br />
          </p>

          <p class="mbr-text">
            <i class="fa-solid fa-phone"></i>&nbsp;&nbsp;
            <a href="tel:(023) 92 82 99 33">(023) 92 82 99 33</a>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-2">
          <h4 class="pt-2">Links</h4>
          <p class="mbr-text">
            <i class="fa-brands fa-facebook"></i>&nbsp;&nbsp;<a class=""
              href="https://www.facebook.com/phoenixblindsandshutters/">facebook</a><br />
          </p>
          <p class="mbr-text">
            <i class="fa-brands fa-instagram"></i>&nbsp;&nbsp;<a class=""
              href="https://www.instagram.com/phoenixblindsandshutters">instagram</a><br />
          </p>

        </div>
        <div class="col-sm-12 col-md-6 col-lg-2">
          <h4 class="pt-2">Policies</h4>
          <p>
            <i class="fa-solid fa-ban"></i>&nbsp;&nbsp;
            <a [routerLink]="'privacy'">Privacy</a><br />
          </p>

        </div>
      </div>
      <div class="footer-lower">
        <div class="row">
          <div class="col-sm-12">
            <hr />
          </div>
        </div>
        <div class="row pb-3">
          <div class="col-sm-6">
            <p>
              © Copyright {{ currentYear }} Phoenix Blinds & Shutters Ltd - All Rights Reserved
            </p>
          </div>
          <div class="col-sm-6">
            <p>
              Website created by <a href="https://customwebsolutions.ltd">Custom Web Solutions Ltd</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>