import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';

import { Amplify } from '@aws-amplify/core';
import awsconfig from 'src/aws-exports';
import { AppComponent } from './app/app.component';
import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';

Amplify.configure(awsconfig);
// Amplify.configure({ ...awsconfig, ssr: true });

if (environment.production) {
  enableProdMode();
}

// import {Amplify} from '@aws-amplify/core';

// import aws_exports from './aws-exports';

// Amplify.configure(aws_exports);

bootstrapApplication(AppComponent, appConfig)
  .catch(err => console.error(err));
