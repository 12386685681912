import { Routes } from "@angular/router";

export const routes: Routes = [
  {
    path: "",
    title: "Phoenix Blinds and Shutters Ltd",
    loadComponent: () =>
      import("./pages/home/home.component").then((c) => c.HomeComponent),
  },
  {
    path: "home",
    title: "Phoenix Blinds and Shutters Ltd",
    loadComponent: () =>
      import("./pages/home/home.component").then((c) => c.HomeComponent),
  },
  {
    path: "privacy",
    title: "Privacy Policy",
    loadComponent: () =>
      import("./pages/privacy/privacy.component").then(
        (c) => c.PrivacyComponent
      ),
  },
  {
    path: "roller",
    title: "Roller Blinds",
    loadComponent: () =>
      import("./pages/roller/roller.component").then((c) => c.RollerComponent),
  },
  {
    path: "pleated",
    title: "Pleated Blinds",
    loadComponent: () =>
      import("./pages/pleated/pleated.component").then(
        (c) => c.PleatedComponent
      ),
  },
  {
    path: "vertical",
    title: "Vertical Blinds",
    loadComponent: () =>
      import("./pages/vertical/vertical.component").then(
        (c) => c.VerticalComponent
      ),
  },
  {
    path: "venetian",
    title: "Venetian Blinds",
    loadComponent: () =>
      import("./pages/venetian/venetian.component").then(
        (c) => c.VenetianComponent
      ),
  },
  {
    path: "perfect-fit",
    title: "Perfect Fit Blinds",
    loadComponent: () =>
      import("./pages/perfect-fit/perfect-fit.component").then(
        (c) => c.PerfectFitComponent
      ),
  },
  {
    path: "conservatory",
    title: "Conservatory Blinds",
    loadComponent: () =>
      import("./pages/conservatory/conservatory.component").then(
        (c) => c.ConservatoryComponent
      ),
  },
  {
    path: "spares",
    title: "Blind Spares",
    loadComponent: () =>
      import("./pages/spares/spares.component").then((c) => c.SparesComponent),
  },
  {
    path: "curved",
    title: "Curved Blinds",
    loadComponent: () =>
      import("./pages/curved/curved.component").then((c) => c.CurvedComponent),
  },
  {
    path: "shutters",
    title: "Wooden Shutters",
    loadComponent: () =>
      import("./pages/shutters/shutters.component").then(
        (c) => c.ShuttersComponent
      ),
  },
  {
    path: "shutter-quote",
    title: "Shutter Quote",
    loadComponent: () =>
      import("./pages/shutter-quote/shutter-quote.component").then(
        (c) => c.ShutterQuoteComponent
      ),
  },
  {
    path: "intu",
    title: "Intu Blinds",
    loadComponent: () =>
      import("./pages/intu/intu.component").then((c) => c.IntuComponent),
  },
  {
    path: "blogs",
    title: "Blog",
    loadComponent: () =>
      import("./pages/blog/blog.component").then((c) => c.BlogComponent),
  },
  {
    path: "blog/:blogId",
    loadComponent: () =>
      import("./pages/blog-full-post/blog-full-post.component").then(
        (c) => c.BlogFullPostComponent
      ),
  },
  {
    path: "love-shutters",
    title: "Love Shutters",
    loadComponent: () =>
      import("./pages/offer-landing-page/offer-landing-page.component").then(
        (c) => c.OfferLandingPageComponent
      ),
  },
  {
    path: "shutter-offer",
    title: "Shutter Offer",
    loadComponent: () =>
      import("./pages/offer-landing-page/offer-landing-page.component").then(
        (c) => c.OfferLandingPageComponent
      ),
  },
  {
    path: "chichester",
    title: "Phoenix Blinds and Shutters Ltd | Chichester",
    loadComponent: () =>
      import(
        "./pages/chichester-landing-page/chichester-landing-page.component"
      ).then((c) => c.ChichesterLandingPageComponent),
  },
  {
    path: "petersfield",
    title: "Phoenix Blinds and Shutters Ltd | Petersfield",
    loadComponent: () =>
      import(
        "./pages/petersfield-landing-page/petersfield-landing-page.component"
      ).then((c) => c.PetersfieldLandingPageComponent),
  },
  {
    path: "contact-us",
    title: "Contact Us",
    loadComponent: () =>
      import("./pages/contact-us/contact-us.component").then(
        (c) => c.ContactUsComponent
      ),
  },
  {
    path: "dijon-blinds",
    title: "Dijon Blinds",
    loadComponent: () =>
      import("./pages/dijon/dijon.component").then((c) => c.DijonComponent),
  }
  ];
  