/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cognito_identity_pool_id": "eu-west-2:cba51d52-7a90-41e1-851f-5d088e601edc",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_bnTRBLfrI",
    "aws_user_pools_web_client_id": "4csbu65re9m96plol15ka6012m",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "phoenixblinds318a9bb54c644f878ef85930c0a3a30713551-master",
    "aws_user_files_s3_bucket_region": "eu-west-2"
};


export default awsmobile;
