import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { BrowserModule, provideClientHydration, withEventReplay } from '@angular/platform-browser';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { NgxMasonryModule } from 'ngx-masonry';
import { DataService } from './services/data.service';
import { PopupService } from './services/popup.service';
import { QuoteService } from './services/quote.service';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes), 
    provideClientHydration(),
   importProvidersFrom(BrowserModule, FormsModule, ReactiveFormsModule), // NgxMasonryModule
   DataService,
   QuoteService,
   PopupService,
   provideAnimations(),
   provideHttpClient(withInterceptorsFromDi()), 
   provideAnimationsAsync()
  ]
};
