import { Component, OnInit } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { FooterComponent } from "./components/footer/footer.component";
import { RouterOutlet } from "@angular/router";
import { HeaderComponent } from "./components/header/header.component";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  standalone: true,
  imports: [HeaderComponent, RouterOutlet, FooterComponent],
})
export class AppComponent implements OnInit {
  constructor(private metaTagService: Meta, private titleService: Title) {}
  ngOnInit() {
    // this.titleService.setTitle("Phoenix Blinds and Shutters Ltd");
    // this.metaTagService.addTags([
    //   {
    //     name: "keywords",
    //     content:
    //       "custom made blinds, roller blinds for windows, venetian blinds, blinds for bay windows, vertical blinds for windows",
    //   },
    //   { name: "title", content: "Phoenix Blinds and Shutters Ltd" },
    //   { name: "robots", content: "index, follow" },
    //   { name: "author", content: "Phoenix Blinds and Shutters Ltd" },
    //   { name: "viewport", content: "width=device-width, initial-scale=1" },
    //   { charset: "UTF-8" },
    // ]);
  }
}
